import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { HipaaService } from '@wndr/common/core/services/hipaa.service';
import { toggleExecutionState } from '@wndr/common/core/utils/rxjs/toggle-execution-state';
import { AbstractModalComponent } from '@wndr/common/shared/components/abstract-modal-component/abstract-modal-component';
import { ButtonDirective } from '@wndr/common/shared/components/buttons/button.directive';
import { CheckboxComponent } from '@wndr/common/shared/components/controls/checkbox/checkbox.component';
import { InputErrorWrapperComponent } from '@wndr/common/shared/components/error-wrappers/input-error-wrapper/input-error-wrapper.component';
import { LoaderComponent } from '@wndr/common/shared/components/loader/loader.component';
import { ModalWrapperComponent } from '@wndr/common/shared/components/modal-wrapper/modal-wrapper.component';
import { switchMap } from 'rxjs';

/** HIPAA compliance modal ID. */
export const HIPAA_COMPLIANCE_MODAL_ID = 'hipaa-compliance-modal-id';

/** Hipaa compliance modal data. */
export type HipaaComplianceModalData = Readonly<{

	/** Token. */
	token?: string;
}>;

/** HIPAA compliance modal. */
@Component({
	selector: 'wndrc-hipaa-compliance-modal',
	standalone: true,
	templateUrl: './hipaa-compliance-modal.component.html',
	styleUrl: './hipaa-compliance-modal.component.css',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		AsyncPipe,
		ModalWrapperComponent,
		InputErrorWrapperComponent,
		CheckboxComponent,
		ReactiveFormsModule,
		LoaderComponent,
		ButtonDirective,
	],
})
export class HipaaComplianceModalComponent extends AbstractModalComponent<HipaaComplianceModalData, boolean> {

	private readonly fb = inject(NonNullableFormBuilder);

	/** @inheritdoc */
	protected override readonly id = HIPAA_COMPLIANCE_MODAL_ID;

	private readonly hipaaService = inject(HipaaService);

	/** Whether loading HIPAA compliance. */
	protected readonly isLoadingHipaaCompliance = signal(false);

	private readonly token = computed(() => this.modalData().token);

	/** Hipaa compliance. */
	protected readonly hipaaCompliance$ = toObservable(this.token).pipe(
		switchMap(token => this.hipaaService.getHipaaCompliance(token).pipe(
			toggleExecutionState(this.isLoadingHipaaCompliance),
		)),
	);

	/** Form control. */
	protected readonly isAgreeToCompliance = this.fb.control(false, [Validators.requiredTrue]);

	/** Handles modal submit button click. */
	protected onSubmitButtonClick(): void {
		this.isAgreeToCompliance.markAsTouched();
		if (this.isAgreeToCompliance.invalid) {
			return;
		}
		this.close({ data: this.isAgreeToCompliance.value });
	}
}
