<wndrc-modal-wrapper title="Inactive">
	<p class="body-1">
		You will be logged out during {{ timer$ | async | wndrcTime }} seconds.
		<br />
		Click “Cancel” to continue work
	</p>
	<button
		slot="bottom"
		type="button"
		wndrcButton
		(click)="onCancelButtonClick()"
	>
		Cancel
	</button>
</wndrc-modal-wrapper>
