import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

import { AppConfig } from '../services/app.config';
import { UserSecretStorageService } from '../services/user-secret-storage.service';
import { AUTH_HEADER_KEY } from '../constants/auth';
import { composeAuthorizationHeader } from '../utils/compose-auth-header';
import { AppUrlsConfig } from '../services/app-urls.config';

/** Adds JWT to requests using Authorization HTTP header. */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	private readonly appConfigService = inject(AppConfig);

	private readonly apiUrlsConfig = inject(AppUrlsConfig);

	private readonly userSecretStorage = inject(UserSecretStorageService);

	/** @inheritdoc */
	public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		if (this.shouldInterceptToken(req.url)) {
			const userSecret$ = this.userSecretStorage.currentSecret$.pipe(first());

			return userSecret$.pipe(
				map(userSecret =>
					userSecret && !req.headers.has(AUTH_HEADER_KEY) ?
						req.clone({
							headers: composeAuthorizationHeader(userSecret.token, req.headers),
						}) :
						req),
				switchMap(newReq => next.handle(newReq)),
			);
		}

		// Do nothing.
		return next.handle(req);
	}

	/**
	 * Checks if a request is for authorization or refresh token.
	 * @param url - Request url.
	 */
	private shouldInterceptToken(url: string): boolean {
		return (
			url.startsWith(this.appConfigService.apiUrl) &&

			// To avoid adding authorization header for refresh secret endpoint.
			url !== this.apiUrlsConfig.auth.refreshSecret
		);
	}
}
