import { from, map, Observable, of, switchMap } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import {
	HIPAA_COMPLIANCE_MODAL_ID,
	HipaaComplianceModalComponent,
} from '@wndr/common/shared/features/home/components/hipaa-compliance-modal/hipaa-compliance-modal.component';

import { ModalService } from './modal.service';
import { HipaaService } from './hipaa.service';

/** HIPAA compliance modal service. */
@Injectable({ providedIn: 'root' })
export class HipaaComplianceModalService {
	private readonly hipaaService = inject(HipaaService);

	private readonly modalService = inject(ModalService);

	/**
	 * Runs HIPAA compliance process.
	 * @param token Authorization token.
	 * @returns Observable that emits true if agreement is accepted, false otherwise.
	 */
	public runHipaaComplianceProcess(token?: string): Observable<boolean> {
		return from(
			this.modalService.open(HipaaComplianceModalComponent, {
				id: HIPAA_COMPLIANCE_MODAL_ID,
				modalData: { token },
				backdropDismiss: false,
			}),
		).pipe(
			switchMap(modal => modal.afterClosed()),
			map(Boolean),
			switchMap(result => {
				if (result) {
					return this.hipaaService.markHipaaComplianceAsRead(token).pipe(map(() => true));
				}
				return of(false);
			}),
		);
	}
}
