import { Injectable } from '@angular/core';

import { HipaaComplianceDto } from '../dtos/hipaa-compliance.dto';
import { HipaaCompliance } from '../models/hipaa-compliance';

import { MapperFromDto } from './mappers';

/** HIPAA compliance mapper. */
@Injectable({ providedIn: 'root' })
export class HipaaComplianceMapper implements MapperFromDto<HipaaComplianceDto, HipaaCompliance> {

	/** @inheritdoc */
	public fromDto(dto: HipaaComplianceDto): HipaaCompliance {
		return {
			id: dto.id,
			content: dto.content,
			modifiedAt: new Date(dto.modified),
			createdAt: new Date(dto.created),
		};
	}
}
