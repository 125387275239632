import { importProvidersFrom } from '@angular/core';
import { provideRouter, withInMemoryScrolling, withRouterConfig } from '@angular/router';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthInterceptor } from '@wndr/common/core/interceptors/auth.interceptor';
import { AppConfig } from '@wndr/common/core/services/app.config';
import { provideIonicAngular } from '@ionic/angular/standalone';
import { IonicModule } from '@ionic/angular';
import { DatePipe } from '@angular/common';
import { previousUrlServiceProvider } from '@wndr/common/core/services/previous-url.service';
import { TwoFactorInterceptor } from '@wndr/common/core/interceptors/two-factor.interceptor';
import { HipaaComplianceInterceptor } from '@wndr/common/core/interceptors/hipaa.interceptor';
import { ConfirmEmailInterceptor } from '@wndr/common/core/interceptors/confirm-email.interceptor';
import { ConfirmSecondaryEmailInterceptor } from '@wndr/common/core/interceptors/confirm-secondary-email.interceptor';
import { RefreshService } from '@wndr/common/core/services/refresh.service';

import { appRoutes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { WebAppConfig } from './app/features/shared/web-app.config';
import { provideWebAppRoutes } from './app/features/shared/web-route-paths';
import { provideWebDownloadService } from './app/features/shared/web-download.service';
import { provideChatLinkService } from './app/core/chat-link.service';
import { WebRefreshTokenInterceptor } from './app/core/interceptors/refresh-token.interceptor';

const httpInterceptorProviders = [
	// The refresh interceptor should be before the auth interceptor, otherwise refreshed bearer would not be updated
	{
		provide: HTTP_INTERCEPTORS,
		useClass: WebRefreshTokenInterceptor,
		multi: true,
	},
	{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
	{ provide: HTTP_INTERCEPTORS, useClass: HipaaComplianceInterceptor, multi: true },
	{ provide: HTTP_INTERCEPTORS, useClass: TwoFactorInterceptor, multi: true },
	{ provide: HTTP_INTERCEPTORS, useClass: ConfirmEmailInterceptor, multi: true },
	{ provide: HTTP_INTERCEPTORS, useClass: ConfirmSecondaryEmailInterceptor, multi: true },
];

bootstrapApplication(AppComponent, {
	providers: [
		importProvidersFrom(BrowserModule),
		...httpInterceptorProviders,
		{ provide: AppConfig, useClass: WebAppConfig },
		provideAnimations(),
		provideHttpClient(withInterceptorsFromDi()),
		provideRouter(appRoutes, withRouterConfig({
			/*
				We need to provide this value so "canceled" navigation in "canDeactivate" guard works fine.
			*/
			canceledNavigationResolution: 'computed',
		}), withInMemoryScrolling({ scrollPositionRestoration: 'top' })),
		provideWebAppRoutes(),
		provideIonicAngular(),
		DatePipe,
		previousUrlServiceProvider,
		provideWebDownloadService(),
		provideChatLinkService(),
		importProvidersFrom(IonicModule.forRoot({
			backButtonText: '',
			mode: 'md',
		})),

		// Basically, this service should be provided with module level.
		// But, web project doesn't have refresh feature, but common components use `RefreshService.
		// So, to avoid undefined injector issue, provide this service here.
		RefreshService,
	],
})
	.catch(err => console.error(err));
