import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { from, map, Observable, shareReplay, switchMap, tap } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import {
	CAN_DISMISS_CONFIRM_EMAIL_MODAL_ROLE,
	CONFIRM_EMAIL_DIALOG_ID,
	ConfirmEmailDialogComponent,
} from '@wndr/common/shared/components/confirm-email-dialog/confirm-email-dialog.component';

import { catchHttpErrorResponse } from '../utils/rxjs/catch-http-error-response';
import { AppUrlsConfig } from '../services/app-urls.config';
import { isValidationErrorDto } from '../dtos/validation-error.dto';
import { ModalService } from '../services/modal.service';

const UNCONFIRMED_EMAIL_ERROR_DETAILS_MESSAGE = 'You have not confirm email address.';

/** Catch error related to confirmation email and request a user to confirm. */
@Injectable()
export class ConfirmEmailInterceptor implements HttpInterceptor {

	/** Confirm email process. */
	private confirmEmailProcess: Observable<void> | null = null;

	private readonly modalService = inject(ModalService);

	private readonly apiUrlsConfig = inject(AppUrlsConfig);

	/** @inheritdoc */
	public intercept(
		req: HttpRequest<unknown>,
		next: HttpHandler,
	): Observable<HttpEvent<unknown>> {
		if (!this.shouldRefreshTokenForUrl(req.url)) {
			return next.handle(req);
		}

		return next.handle(req).pipe(
			catchHttpErrorResponse(error => {
				if (this.shouldHttpErrorBeIgnored(error)) {
					throw error;
				}

				this.confirmEmailProcess ??= this.runEmailConfirmation().pipe(
					shareReplay({ refCount: true, bufferSize: 1 }),
				);

				return this.confirmEmailProcess.pipe(
					tap(() => {
						this.confirmEmailProcess = null;
					}),
					switchMap(() => next.handle(req)),
				);
			}),
		);
	}

	private shouldHttpErrorBeIgnored(error: HttpErrorResponse): boolean {
		if (error.status !== HttpStatusCode.Forbidden || !isValidationErrorDto(error.error)) {
			return true;
		}

		return error.error.errors[0].detail !== UNCONFIRMED_EMAIL_ERROR_DETAILS_MESSAGE;
	}

	private shouldRefreshTokenForUrl(url: string): boolean {
		return this.apiUrlsConfig.isApplicationUrl(url);
	}

	private runEmailConfirmation(): Observable<void> {
		return from(this.modalService.open(ConfirmEmailDialogComponent, {
			id: CONFIRM_EMAIL_DIALOG_ID,
			modalData: { isSecondaryEmail: false, sendImmediately: true },
			canDismiss: (_: unknown, role?: string) => Promise.resolve(role === CAN_DISMISS_CONFIRM_EMAIL_MODAL_ROLE),
		})).pipe(
			switchMap(ref => ref.afterClosed()),
			map(() => undefined),
		);
	}
}
