import { Injectable } from '@angular/core';
import { AppConfig } from '@wndr/common/core/services/app.config';
import { Observable, of } from 'rxjs';

/** App-specific implementation of app config. */
@Injectable()
export class WebAppConfig extends AppConfig {

	/** @inheritdoc */
	public override readonly apiUrl: string = import.meta.env.NG_APP_API_URL;

	/** @inheritdoc */
	public override readonly version$ = this.getAppVersionStream();

	/** @inheritdoc */
	public override readonly originUrl: string = import.meta.env.NG_APP_ORIGIN_URL;

	/** @inheritdoc */
	public override readonly customSchemeUrl: string = import.meta.env.NG_APP_CUSTOM_URL_SCHEME;

	/** @inheritdoc */
	public override readonly vonageApiKey = import.meta.env.NG_APP_VONAGE_API_KEY;

	/** @inheritdoc */
	public override readonly stripeApiKey = import.meta.env.NG_APP_STRIPE_API_KEY;

	/** Provides app version. */
	private getAppVersionStream(): Observable<string> {
		return of(import.meta.env.NG_APP_VERSION);
	}
}
