import { APP_INITIALIZER, DestroyRef, Injectable, Provider, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs';

/** Previous urls service. */
@Injectable({ providedIn: 'root' })
export class PreviousUrlService {

	private readonly destroyRef = inject(DestroyRef);

	private readonly router = inject(Router);

	private readonly previousUrl = signal<string | null>(null);

	public constructor() {
		this.observeRouting();
	}

	/** Starts observations of routing. */
	private observeRouting(): void {
		this.router.events
			.pipe(
				filter((event): event is RoutesRecognized => event instanceof RoutesRecognized),
				pairwise(),
				takeUntilDestroyed(this.destroyRef),
			)
			.subscribe(events => {
				this.previousUrl.set(events[0].urlAfterRedirects);
			});
	}

	/**
	 * Gets previous url visited by user.
	 * @param defaultUrl Default URL to use if there is no previous URL.
	 */
	public getPreviousUrl(defaultUrl: string): string {
		return this.previousUrl() ?? defaultUrl;
	}
}

/** Previous URL service provider. */
export const previousUrlServiceProvider: Provider = {
	provide: APP_INITIALIZER,
	useFactory: (service: PreviousUrlService) => () => service,
	deps: [PreviousUrlService],
	multi: true,
};
