<wndrc-modal-wrapper
	title="HIPAA Compliance"
	[showCloseButton]="false"
>
	@if ({ compliance: hipaaCompliance$ | async }; as content) {
		@if (isLoadingHipaaCompliance()) {
			<wndrc-loader mode="inline" />
		} @else {
			<div [innerHTML]="content.compliance?.content"></div>
		}
	}
	<div
		slot="bottom"
		class="footer"
	>
		<wndrc-input-error-wrapper shouldReserveSpace>
			<wndrc-checkbox [formControl]="isAgreeToCompliance">
				By checking here, I confirm that I agree with the above and to protect patient privacy.
			</wndrc-checkbox>
		</wndrc-input-error-wrapper>
		<button
			wndrcButton
			type="button"
			(click)="onSubmitButtonClick()"
		>
			Submit
		</button>
	</div>
</wndrc-modal-wrapper>
