import { inject, Injectable, Provider } from '@angular/core';
import { ChatLinkService } from '@wndr/common/core/services/chat-link.service';
import { ChatService } from '@wndr/common/core/services/chat.service';
import { NotificationService } from '@wndr/common/core/services/notification.service';
import { Observable, tap } from 'rxjs';

/** Web implementation of chat link service. */
@Injectable()
export class WebChatLinkService extends ChatLinkService {

	private readonly chatService = inject(ChatService);

	private readonly notificationService = inject(NotificationService);

	/** @inheritdoc */
	public override generateChatLink(): Observable<string> {
		return this.chatService.createChatInvitationLink().pipe(
			tap(link => navigator.clipboard.writeText(link)),
			tap(() => this.notificationService.notify('success', 'Link copied to clipboard')),
		);
	}
}

/** Provider factory for ChatLinkService. */
export function provideChatLinkService(): Provider {
	return {
		provide: ChatLinkService,
		useClass: WebChatLinkService,
	};
}
