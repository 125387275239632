import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterOutlet } from '@angular/router';
import { DotLottieFileSrc } from '@wndr/common/core/models/dotlottie-file-src';
import { DotLottieCacheService } from '@wndr/common/core/services/dotlottie-cache.service';
import { PlatformService } from '@wndr/common/core/services/platform.service';
import { filter, from, map, Observable, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { UserService } from '@wndr/common/core/services/user.service';
import { ModalService } from '@wndr/common/core/services/modal.service';
import { RedirectService } from '@wndr/common/core/services/redirect.service';
import { CallService } from '@wndr/common/core/services/call.service';

import { INACTIVE_MODAL_ID, InactiveModalComponent } from './features/shared/inactive-modal/inactive-modal.component';
import { IdleService } from './core/idle.service';

/** Root component. */
@Component({
	selector: 'wndrw-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [RouterOutlet],
})
export class AppComponent implements OnInit {
	private readonly destroyRef = inject(DestroyRef);

	private readonly platformService = inject(PlatformService);

	private readonly modalService = inject(ModalService);

	private readonly userService = inject(UserService);

	private readonly dotLottieCacheService = inject(DotLottieCacheService);

	private readonly isInactiveModalOpened = signal(false);

	private readonly redirectsService = inject(RedirectService);

	private readonly callService = inject(CallService);

	private readonly idleService = inject(IdleService);

	public constructor() {
		this.platformService.markPlatformAsWeb();
		this.dotLottieCacheService.cacheBySrc(DotLottieFileSrc.Loader);
	}

	/** @inheritdoc */
	public ngOnInit(): void {
		this.whenUserInactive().pipe(
			filter(Boolean),
			switchMap(() => this.userService.logout()),
			takeUntilDestroyed(this.destroyRef),
		)
			.subscribe(() => {
				this.redirectsService.redirectToAuthPage();
			});
	}

	/** Emits when user is inactive. */
	public whenUserInactive(): Observable<boolean> {
		return this.idleService.onIdle({
			// 24 hours.
			maximumInactivityTimeInMinutes: 24 * 60,

			// Notify user about inactivity one minute before the end.
			notifyAfterMinutes: 24 * 60 - 1,
		}).pipe(
			withLatestFrom(this.userService.isAuthorized$),
			filter(([_, isAuthorized]) => (
				isAuthorized &&
				!this.isInactiveModalOpened() &&
				!this.callService.isCallInProgress
			)),
			switchMap(([{ timeLeftUntilTimeoutInSeconds }]) => {
				if (timeLeftUntilTimeoutInSeconds > 0) {
					this.isInactiveModalOpened.set(true);
					return from(this.modalService.open(InactiveModalComponent, {
						id: INACTIVE_MODAL_ID,
						modalData: { timeLeftUntilTimeoutInSeconds },
					})).pipe(
						switchMap(modal => modal.afterClosed()),
						map(Boolean),
						tap(() => this.isInactiveModalOpened.set(false)),
					);
				}
				return of(true);
			}),
		);
	}
}
