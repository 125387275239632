import { inject, Injectable } from '@angular/core';
import { RefreshTokenInterceptor } from '@wndr/common/core/interceptors/refresh-token.interceptor';
import { RedirectService } from '@wndr/common/core/services/redirect.service';
import { UserService } from '@wndr/common/core/services/user.service';
import { Observable, tap } from 'rxjs';

/**
 * Refresh token interceptor for the web platform.
 * Catches requests with outdated tokens and logout from the app.
 */
@Injectable()
export class WebRefreshTokenInterceptor extends RefreshTokenInterceptor {
	private readonly userService = inject(UserService);

	private readonly redirectsService = inject(RedirectService);

	/** @inheritdoc */
	protected override initializeRefreshSecretRequest(): Observable<void> {
		return this.userService.logout().pipe(tap(() => this.redirectsService.redirectToAuthPage()));
	}
}
