import { map, Observable } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { HipaaCompliance } from '../models/hipaa-compliance';
import { hipaaComplianceDtoSchema } from '../dtos/hipaa-compliance.dto';
import { HipaaComplianceMapper } from '../mappers/hipaa-compliance.mapper';
import { filterNull } from '../utils/rxjs/filter-null';
import { composeAuthorizationHeader } from '../utils/compose-auth-header';

import { AppUrlsConfig } from './app-urls.config';

/** HIPAA service. */
@Injectable({ providedIn: 'root' })
export class HipaaService {

	private readonly http = inject(HttpClient);

	private readonly apiUrlsConfig = inject(AppUrlsConfig);

	private readonly hipaaComplianceMapper = inject(HipaaComplianceMapper);

	/**
		* Gets HIPAA compliance.
		* @param token Token.
		*/
	public getHipaaCompliance(token?: string): Observable<HipaaCompliance> {
		const headers = token ? composeAuthorizationHeader(token) : undefined;
		return this.http.get<unknown>(this.apiUrlsConfig.hipaa.hipaaCompliance, { headers }).pipe(
			map(response => hipaaComplianceDtoSchema.parse(response)),
			map(complianceDto => this.hipaaComplianceMapper.fromDto(complianceDto)),
			filterNull(),
		);
	}

	/**
		* Marks hipaa compliance as read.
		* @param token Token.
		*/
	public markHipaaComplianceAsRead(token?: string): Observable<void> {
		const headers = token ? composeAuthorizationHeader(token) : undefined;
		return this.http.post<unknown>(this.apiUrlsConfig.hipaa.markHipaaComplianceAsRead, {}, { headers }).pipe(
			map(() => undefined),
		);
	}
}
