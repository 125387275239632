import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { map, Observable, shareReplay, switchMap, tap } from 'rxjs';
import { inject, Injectable } from '@angular/core';

import { catchHttpErrorResponse } from '../utils/rxjs/catch-http-error-response';
import { AppUrlsConfig } from '../services/app-urls.config';
import { HipaaComplianceModalService } from '../services/hipaa-compliance-modal.service';
import { isValidationErrorDto } from '../dtos/validation-error.dto';
import { getAuthorizationHeaderToken } from '../utils/get-authorization-header-token';

const HIPAA_ERROR_DETAILS_MESSAGE = 'You have not read HIPAA Compliance, please read before using the application.';

/** Catch error related to hipaa and request a user to read new agreement. */
@Injectable()
export class HipaaComplianceInterceptor implements HttpInterceptor {

	/** Hipaa requirement process. */
	private hipaaRequirementProcess: Observable<void> | null = null;

	private readonly hipaaService = inject(HipaaComplianceModalService);

	private readonly apiUrlsConfig = inject(AppUrlsConfig);

	/** @inheritdoc */
	public intercept(
		req: HttpRequest<unknown>,
		next: HttpHandler,
	): Observable<HttpEvent<unknown>> {
		if (!this.shouldRefreshTokenForUrl(req.url)) {
			return next.handle(req);
		}

		return next.handle(req).pipe(
			catchHttpErrorResponse(error => {
				if (this.shouldHttpErrorBeIgnored(error)) {
					throw error;
				}

				const token = getAuthorizationHeaderToken(req.headers);
				this.hipaaRequirementProcess ??= this.runHipaaCompliance(token ?? undefined).pipe(
					shareReplay({ refCount: true, bufferSize: 1 }),
				);

				return this.hipaaRequirementProcess.pipe(
					tap(() => {
						this.hipaaRequirementProcess = null;
					}),
					switchMap(() => next.handle(req)),
				);
			}),
		);
	}

	private shouldHttpErrorBeIgnored(error: HttpErrorResponse): boolean {
		if (error.status !== HttpStatusCode.Forbidden || !isValidationErrorDto(error.error)) {
			return true;
		}
		return error.error.errors[0].detail !== HIPAA_ERROR_DETAILS_MESSAGE;
	}

	private shouldRefreshTokenForUrl(url: string): boolean {
		return this.apiUrlsConfig.isApplicationUrl(url);
	}

	private runHipaaCompliance(token?: string): Observable<void> {
		return this.hipaaService.runHipaaComplianceProcess(token).pipe(map(() => undefined));
	}
}
