import { Routes } from '@angular/router';
import { authGuard } from '@wndr/common/core/guards/auth-guard';
import { SUBMISSION_IOS_REDIRECT, WEB_VIEW_REDIRECT_URL } from '@wndr/common/core/utils/route-paths/common-route-paths';

import { webRoutePaths } from './features/shared/web-route-paths';

/**
 * Routes object are described based on the created by `buildRoutePaths` object.
 * In some cases, when we define children for dynamic parameters, we must initialize
 * this parameter with an empty string, as in the example below.
 * This behavior is allowed in routing modules.
 * @example
 * ```ts
 * const routePaths = buildRoutePaths({
 *   dashboard: {
 *     path: 'dashboard',
 *     children: {
 *       auth: { path: 'auth' },
 *       users: {
 *         path: ':id',
 *         children: {
 *           edit: { path: 'edit' },
 *         },
 *       },
 *     },
 *   },
 * } as const);
 *
 * const routes: Routes = [
 *   {
 *     path: routePaths.dashboard.path,
 *     children: [
 *       {
 *         path: routePaths.dashboard.children.auth.path,
 *         component: AuthComponent,
 *       },
 *       {
 *         path: routePaths.dashboard.children.users.path,
 *         children: [
 *           {
 *             path: routePaths.dashboard.children.users.children({ id: '' }).edit.path,
 *             component: EditUserComponent,
 *           },
 *         ],
 *       },
 *     ],
 *   };
 * ];
 * ```
 */
export const appRoutes: Routes = [
	{
		path: '',
		redirectTo: webRoutePaths.auth.path,
		pathMatch: 'full',
	},
	{
		path: webRoutePaths.auth.path,
		loadChildren: () => import('./features/auth/auth.routes').then(r => r.authRoutes),
		canMatch: [authGuard({ isAuthorized: false })],
	},
	{
		path: webRoutePaths.portal.path,
		loadChildren: () => import('./features/portal/portal.routes').then(r => r.portalRoutes),
		canMatch: [authGuard({ isAuthorized: true })],
	},
	{
		path: webRoutePaths.restore.path,
		loadChildren: () => import('./features/restore/restore.routes').then(r => r.restoreRoutes),
	},
	{
		path: webRoutePaths.policies.path,
		loadChildren: () => import('./features/policies/policies.routes').then(r => r.policiesRoutes),
	},
	{
		path: webRoutePaths.guestChat.path,
		loadComponent: () => import('./features/guest-chat/guest-chat-page/guest-chat-page.component').then(c => c.GuestChatPageComponent),
		canMatch: [authGuard({ isAuthorized: false })],
	},
	{
		path: SUBMISSION_IOS_REDIRECT,
		loadComponent: () => import('./features/redirects/submission-redirect/submission-redirect.component')
			.then(c => c.SubmissionRedirectComponent),
	},
	{
		path: WEB_VIEW_REDIRECT_URL,
		redirectTo: webRoutePaths.portal.children.tabs.children.settings.path,
		pathMatch: 'full',
	},
];
