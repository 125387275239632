import { z } from 'zod';

/** HIPAA compliance DTO schema. */
export const hipaaComplianceDtoSchema = z.object({

	id: z.number(),

	content: z.string(),

	modified: z.string(),

	created: z.string(),
});

/** HIPAA compliance DTO. */
export type HipaaComplianceDto = Readonly<z.infer<typeof hipaaComplianceDtoSchema>>;
