import { HttpHeaders } from '@angular/common/http';

import { AUTH_HEADER_KEY, AUTH_PREFIX } from '../constants/auth';

/**
	* Get authorization token from HTTP headers.
	* @param headers HTTP headers.
	*/
export function getAuthorizationHeaderToken(headers: HttpHeaders): string | null {
	const authorizationHeader = headers.get(AUTH_HEADER_KEY);
	return authorizationHeader ? authorizationHeader.split(`${AUTH_PREFIX} `)[1] : null;
}
