import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { filterNull } from '@wndr/common/core/utils/rxjs/filter-null';
import { TimeUtils } from '@wndr/common/core/utils/time-utils';
import { filter, fromEvent, interval, map, merge, Observable, repeat, skipWhile, takeUntil, tap } from 'rxjs';

type IdleConfig = Readonly<{

	/** Maximum inactivity time in minutes. */
	maximumInactivityTimeInMinutes: number;

	/** Time after that to notify about inactivity. */
	notifyAfterMinutes: number;
}>;

type IdleResult = Readonly<{

	/** How much time is left until the timeout in seconds. */
	timeLeftUntilTimeoutInSeconds: number;
}>;

/** Tracks user's activity. */
@Injectable({ providedIn: 'root' })
export class IdleService {
	private readonly document = inject(DOCUMENT);

	/**
		* Emits event after the user doesn't interact
		* with the app for the provided time.
		* @param config Idle configuration.
		*/
	public onIdle(config: IdleConfig): Observable<IdleResult> {
		const maximumInactivityTimeMs = TimeUtils.toMilliseconds({ minutes: config.maximumInactivityTimeInMinutes });
		const notifyAfterTimeMs = TimeUtils.toMilliseconds({ minutes: config.notifyAfterMinutes });

		const activityEvents = ['keypress', 'click', 'wheel', 'mousemove', 'ontouchstart', 'mousedown', 'scroll'];
		const activityEvents$ = activityEvents.map(eventName => fromEvent(this.document, eventName));

		// Date initialized from the beginning of the countdown.
		let startDate = new Date();

		const onVisibilityChange$: Observable<IdleResult> = fromEvent(
			this.document,
			'visibilitychange',
		).pipe(
			filter(() => this.document.visibilityState === 'visible'),
			map(() => {
				const passedMs = Math.floor(new Date().getTime() - startDate.getTime());
				if (passedMs >= maximumInactivityTimeMs) {
					return { timeLeftUntilTimeoutInSeconds: 0 };
				} else if (passedMs >= notifyAfterTimeMs) {
					const timeLeftUntilTimeoutInSeconds = Math.floor((maximumInactivityTimeMs - passedMs) / 1000);
					return { timeLeftUntilTimeoutInSeconds };
				}

				return null;
			}),
			filterNull(),
		);

		const onIdle$ = interval(notifyAfterTimeMs).pipe(
			// eslint-disable-next-line rxjs/no-unsafe-takeuntil
			takeUntil(merge(...activityEvents$).pipe(
				tap(() => (startDate = new Date())),
			)),
			skipWhile(() => this.document.visibilityState === 'hidden'),
			map(() => ({ timeLeftUntilTimeoutInSeconds: Math.floor((maximumInactivityTimeMs - notifyAfterTimeMs) / 1000) })),
			repeat(),
		);

		return merge(
			onIdle$,
			onVisibilityChange$,
		);
	}
}
